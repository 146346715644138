import { Injectable } from '@angular/core';
import { environment } from '@environments/environment';
import { ServiceCall } from './service-call.service';
import { Observable, map } from 'rxjs';
import { ILegalText } from '../models/consent-management.model';

export const LEGAL_TEXTS = 'v1/legal-texts';

@Injectable()
export class LegalTextsService {
  private legalTextsUrl = `${environment.apiBaseUrl}/${LEGAL_TEXTS}`;

  constructor(private serviceCall: ServiceCall) {}

  getTermsOfUse(langugae: string): Observable<Array<ILegalText>> {
    return this.serviceCall.get(`${this.legalTextsUrl}/terms-of-use?language=${langugae}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data;
        }
      })
    );
  }

  getImprint(langugae: string): Observable<Array<ILegalText>> {
    return this.serviceCall.get(`${this.legalTextsUrl}/imprint?language=${langugae}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data;
        }
      })
    );
  }

  getDataPrivacy(langugae: string): Observable<Array<ILegalText>> {
    return this.serviceCall.get(`${this.legalTextsUrl}/data-privacy?language=${langugae}`).pipe(
      map((response) => {
        if (response.statusCode === 200) {
          return response.data;
        }
      })
    );
  }
}
